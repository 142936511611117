



























import { Component, Vue } from "vue-property-decorator";
import { NotifyTemplateCreateOrUpdateDto } from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "notifyDetail",
  filters: {
    formatType(type?: string) {
      switch (type) {
        case "ProjectAssessmentPlan":
          return "评估计划通知模板";

        case "OrganizationInvestigation":
          return "尽职调查通知模板";
      }
    },
  },
})
export default class NotifyDetail extends Vue {
  detail: NotifyTemplateCreateOrUpdateDto = {};
  typeList: any = [];

  created() {
    if (this.$route.params.id) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }
  // 获取详情
  async fetchDetail() {
    api.notifyTemplate
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
